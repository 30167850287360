import TemplateContents from './TemplateContents';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();
    }

    reset() {
        super.reset();

        this.setVars();
    }

    destruct() {
        super.destruct();
    }

    setVars() {
        super.setVars();
    }

    setDom() {
        super.setDom();

        this.h1 = document.querySelector('main.cookie_policy h1');
    }

    initEvents() {
        super.initEvents();
    }

    start() {
        // this.setValues();
        TweenMax.delayedCall(0.9, () => {
            this.h1.classList.add('show');
        });
    }

    setValues() {
        // let items = this.pack.apiJSONList[6].items;
        // let html = '';
        // //昇順ソート
        // items.sort((a, b) => {
        //     if (a.fields.displayOrder < b.fields.displayOrder) return -1;
        //     if (a.fields.displayOrder > b.fields.displayOrder) return 1;
        //     return 0;
        // });
        // for (let i = 0, len = items.length; i < len; i++) {
        //     let item = items[i];
        //     html += this.getHTML(item.fields);
        // }
        // this.contactsContainer.innerHTML = html;
    }

    scrollHandler() {}

    enterframe() {}

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
